import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="container text-center my-5 text-white">
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p> 
      <StaticImage
          src="../images/temp/image_0_0.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Ashur x Lapis Lazuli ring"
          hidden='true' 
          className="d-none"
        />
      <StaticImage
          src="../images/temp/image_0_1.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Ashur x Lapis Lazuli ring"
          hidden='true' 
          className="d-none"
        />
      <StaticImage
          src="../images/temp/image_0_2.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Ashur x Lapis Lazuli ring"
          hidden='true' 
          className="d-none"
        />
    </div>

  </Layout>
)

export default NotFoundPage
